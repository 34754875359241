import { Dayjs } from "dayjs";
import React, { createContext, useState } from "react";
import {
  JobJobStatusEnum,
  SettingParamDomainEnum,
} from "../../api/generated/v1.0/app";
import { UserVO } from "../../api/generated/v1.0/open";
import { SettingKeys, Settings, SettingValues } from "../../constants/settings";

export const INITIAL_TEMPS: Pick<
  GlobalState,
  "tempAuth" | "tempEmail" | "tempUserId" | "tempOtp"
> = {
  tempAuth: undefined,
  tempEmail: undefined,
  tempUserId: undefined,
  tempOtp: undefined,
};

const getInitialSettingsValue = (
  value: string | boolean,
  type: "string" | "boolean",
  domain: SettingParamDomainEnum
): SettingValues => {
  return {
    id: 0,
    value,
    lastModifiedAt: 0,
    valueDataType: type,
    domain,
  };
};

export const INITIAL_SETTINGS: Settings = {
  companyName: getInitialSettingsValue("", "string", "COMPANY"),
  companyDetails: getInitialSettingsValue("", "string", "COMPANY"),
  invoiceLimit: getInitialSettingsValue("3", "string", "INVOICE"),
  userManual: getInitialSettingsValue("", "string", "MANUAL"),
  adminManual: getInitialSettingsValue("", "string", "MANUAL"),
  importPublication: getInitialSettingsValue(true, "boolean", "INVOICE"),
  shouldMaskBankInfo: getInitialSettingsValue(false, "boolean", "COMPANY"),
  ipAccessRestriction: getInitialSettingsValue("", "string", "COMPANY")
};
console.log("INITIAL_SETTINGS")
console.log(INITIAL_SETTINGS)

export const INITIAL_GLOBAL: GlobalState = {
  ...INITIAL_TEMPS,
  settings: Object.freeze(INITIAL_SETTINGS),
  hasFetchedRefreshToken: true,
  bulkDownload: {
    state: "NA",
    link: "",
    createdAt: "",
  },
};

export const GlobalContext = createContext<{
  globalState: GlobalState;
  setGlobalState: (nState: Partial<GlobalState>) => void;
}>({
  globalState: INITIAL_GLOBAL,
  setGlobalState: (nState: Partial<GlobalState>) => {},
});

function GlobalProvider({ children }: React.PropsWithChildren<unknown>) {
  const [state, setState] = useState<GlobalState>(INITIAL_GLOBAL);

  const setGlobalState = (nState: Partial<GlobalState>) => {
    setState((state) => ({ ...state, ...nState }));
  };

  return (
    <GlobalContext.Provider value={{ globalState: state, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;

// Types
type GlobalState = {
  tempAuth?: {
    userId: string;
    password: string;
  };
  tempUserId?: string;
  tempEmail?: string;
  tempOtp?: number;
  settings: Settings;
  hasFetchedRefreshToken: boolean;
  bulkDownload: BulkDownload;
};

export type BulkDownload = {
  state: JobStatus;
  link: string;
  createdAt: string;
};

export type JobStatus = "NA" | "PROCESSING" | "DONE" | "ERROR";
